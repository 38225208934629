<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>กิจกรรม</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-btn color="primary" @click="handleAddActivity">เพิ่ม</v-btn>
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="กิจกรรม/วิทยากร"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :loading="loading"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :items-per-page-options="[
                  10,
                  25,
                  50,
                  { text: 'All', value: -1 }
                ]"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
                @click:row="handleClick"
              >
                <!-- <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>-->
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog Form  -->
    <v-dialog v-model="dialogFormActivity" max-width="150vh" persistent>
      <v-card>
        <v-toolbar card color="primary"
          ><v-btn icon dark @click="dialogFormActivity = false">
            <v-icon>mdi-close</v-icon> </v-btn
          ><v-toolbar-title v-if="isNew">สร้างกิจกรรม</v-toolbar-title>
          <v-toolbar-title v-else>แก้ไขกิจกรรม</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <form>
            <input v-if="editMode" type="hidden" v-model="formModel.id" />
            <v-text-field
              v-model="formModel.title"
              :counter="255"
              label="ชื่อกิจกรรม"
              required
            ></v-text-field>
            <v-textarea
              v-model="formModel.description"
              label="รายละเอียด"
              auto-grow
            ></v-textarea>
            <v-select
              :items="location.items"
              :item-text="'title'"
              :item-value="'id'"
              v-model="formModel.location_id"
              label="สถานที่"
            ></v-select>
            <v-text-field
              v-model="formModel.period"
              label="ระยะเวลา (นาที)"
            ></v-text-field>
            <v-select
              :items="lecturer.items"
              :item-text="(item) => `${item.first_name} ${item.last_name}`"
              :item-value="'id'"
              v-model="formModel.lecturers"
              label="วิทยากร"
              return-object
              multiple
            ></v-select>
            <v-select
              :items="formList"
              :item-text="'title'"
              :item-value="'id'"
              label="ฟอร์มการประเมิน"
              v-model="formModel.form_id"
            ></v-select>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="handleSubmit"
            >บันทึก</v-btn
          >
          <v-btn @click="dialogFormActivity = false">ปิด</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog detail -->
    <v-dialog v-model="dialog" max-width="150vh" scrollable persistent>
      <v-card>
        <v-toolbar card color="primary"
          ><v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon> </v-btn
          ><v-toolbar-title>รายละเอียดกิจกรรม</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col>
              <h3>ชื่อกิจกรรม</h3>
              <p>{{ formModel.title }}</p>
              <h3>รายละเอียด</h3>
              <p>{{ formModel.description }}</p>
              <h3>สถานที่</h3>
              <p>{{ formModel.location }}</p>
              <h3>วิทยากร</h3>
              <ul>
                <li
                  v-for="lecturer in formModel.lecturers"
                  :key="lecturer.message"
                >
                  {{ lecturer.first_name }} {{ lecturer.last_name }}
                </li>
              </ul>
              <h3>ระยะเวลา</h3>
              <p>{{ formModel.period }}</p>
              <h3>ฟอร์มการประเมิน</h3>
              <p>{{ formModel.form.title }}</p>
              <p></p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false">ปิด</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Activity,
  getActivity,
  newActivity,
  updateActivity,
  deleteActivity
} from '@/api/activity'
import { getForm } from '@/api/form'
import { getLocation } from '@/api/location'
import { getLecturer } from '@/api/lecturer'

export default {
  name: 'ActivityList',
  data() {
    return {
      isNew: false,
      loading: true,
      dialog: false,
      dialogFormActivity: false,
      editMode: false,
      location: {
        items: [],
        selected: {}
      },
      lecturer: {
        items: [],
        selected: {}
      },
      formList: [],
      formModel: Object.assign({}, Activity),
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'กิจกรรม',
            value: 'title'
          },
          {
            text: 'สถานที่',
            value: 'location'
          },
          {
            text: 'ระยะเวลา',
            value: 'period'
          },
          {
            text: 'วิทยากร',
            value: 'lecturer'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        {
          text: 'รายละเอียด',
          icon: 'mdi-eye',
          click: this.handleViewItem
        },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        },
        {
          text: 'ลบ',
          icon: 'mdi-close',
          click: this.handleDeleteItem
        }
      ]
      // forms: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([
        getActivity('', 0, 100),
        getForm('', 0, 100),
        getLocation(),
        getLecturer()
      ])
        .then(([activities, forms, locations, lecturers]) => {
          this.complex.items = activities
          this.formList = forms
          this.location.items = locations
          this.lecturer.items = lecturers
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleAddActivity() {
      this.formModel = Object.assign({}, Activity)
      this.editMode = false
      this.dialogFormActivity = true
    },
    handleClick(row) {
      this.handleViewItem(row)
    },
    handleViewItem(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.dialog = true
    },
    handleEditItem(row) {
      this.editMode = true
      this.formModel = Object.assign(this.formModel, row)
      this.dialogFormActivity = true
    },
    handleDeleteItem(row) {
      Vue.$confirm({
        title: 'ลบข้อมูล',
        message: 'คุณต้องการลบข้อมูนี้ใช่หรือไม่',
        button: {
          yes: 'ใช่',
          no: 'ยกเลิก'
        },
        callback: () => {
          deleteActivity(row.id).finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleSubmit() {
      this.loading = true
      const activityData = {
        title: this.formModel.title,
        description: this.formModel.description,
        location_id: this.formModel.location_id,
        period: this.formModel.period,
        form_id: this.formModel.form_id,
        lecturers: this.formModel.lecturers.map((item) => item.id)
      }
      if (this.editMode) {
        updateActivity(this.formModel.id, activityData)
          .then(() => {
            this.complex.items = this.complex.items.map((item) => {
              return item.id == this.formModel.id ? this.formModel : item
            })
            this.dialogFormActivity = false
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        newActivity(activityData)
          .then((resp) => {
            this.complex.items.unshift(resp)
            this.dialogFormActivity = false
          })
          .catch((err) => {
            console.error(err)
            // this.$store.snackbar.text = err.message
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
